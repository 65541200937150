import React from "react";
import UseEmail from "../hooks/useEmail.js";

export const Contact = () => {
  const { loading, submitted, error, sendEmail } = UseEmail(
    `https://public.herotofu.com/v1/a8a1aeb0-0869-11ed-be50-e78da9ee852d`
  );

  const sendExample = () => {
    sendEmail({
      example_user: "user@example.com",
      example_data: new Date().toISOString(),
    });
  };

  return (
    <div class="contact_container">
      <div id="portfolio_header" class="neons">
        <h1>CONTACT</h1>
      </div>
      <div id="form_container">
        <form
          method="POST"
          action="https://public.herotofu.com/v1/a8a1aeb0-0869-11ed-be50-e78da9ee852d"
        >
          <div id="form_input_container">
            <label class="form_line">
              <span class="form_label">Your name</span>
              <input type="text" name="name" placeholder="Full Name" />
            </label>
            <label class="form_line">
              <span class="form_label">Email address</span>
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                required
              />
            </label>
            <label class="form_line">
              <span class="form_label">Message</span>
              <textarea
                name="message"
                rows="3"
                placeholder="Please put your message here!"
              ></textarea>
            </label>
          </div>
          <div class="button_container">
            <button class="contact_btn" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
