import React from 'react'
import Maycie from '../images/Maycie.jpg'

export const About = () => {
    return (
        <div id='about_container'>
            <div id="about_left">
                <h1 class="sign">
                    <span class="fast-flicker">M</span>aycie Mo<span class="flicker">r</span>ris
                </h1>
                <h2>Full Stack Developer | Web Design</h2>
                <h3>Syracuse, NY</h3>
            </div>
            <div id='about_right'>
                <img src={ Maycie } />
            </div>
        </div>
    )
}