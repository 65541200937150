import React from 'react';
import YGG from '../images/ygg.png';
import DiamondApp from '../images/diamond_app.png';
import IceWallet from '../images/ice_wallet.png';
import Mango from '../images/mango.png';

export const Portfolio = () => {
  return (
    <div id="portfolio_container">
      <div id="portfolio_header" class="neons">
        <h1>PORTFOLIO</h1>
      </div>
      <div class="project_row_left">
        <div class="project_preview">
          <img src={YGG} alt="Preview of YGG Mockup Site" />
        </div>
        <div class="project_description_left">
          <h3>Yield Guild Games Redesign (Work in Progress)</h3>
          <p>
            A redesign of{' '}
            <a
              href="https://yieldguild.io/ target='_blank"
              rel="noopener noreferrer"
            >
              yeildguild.io
            </a>{' '}
            and{' '}
            <a
              href="https://yieldguild.games/"
              target="_blank"
              rel="noopener noreferrer"
            >
              yieldguild.games
            </a>{' '}
            merged into one website. Entire website was rebuilt, redesigned and
            executed by me.
          </p>
          <span>
            Click{' '}
            <a
              href="https://ygg-test.vercel.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            to view
          </span>
        </div>
      </div>
      <div class="project_row_right">
        <div class="project_preview">
          <img src={DiamondApp} alt="Preview of the Diamond Ancestry App" />
        </div>
        <div class="project_description_right">
          <h3>Diamond Ancestry App</h3>
          <p>
            This Figma design was created and designed by myself, this app is
            meant for use by the GNWT, Diamonds de Canada, and the American Gem
            Society to mint unique NFTs for diamonds.
          </p>
          <span>
            Click{' '}
            <a
              href="https://www.figma.com/file/PbmMWF4X3roeyNitgoIaTh/Diamond-Wallet?node-id=0%3A1"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            to view on Figma
          </span>
        </div>
      </div>
      <div class="project_row_left">
        <div class="project_preview">
          <img src={IceWallet} alt="Preview of Ice Wallet" />
        </div>
        <div class="project_description_left">
          <h3>Ice Wallet Cold Storage</h3>
          <p>
            The Ice Wallet is a hardware device meant to securely interact with
            the Ravencoin blockchain. I co-designed the UX/UI of this device,
            coming up with the background design as well as choosing icons that
            fit fit the feel of the device.
          </p>
          <span>
            Click{' '}
            <a
              href="https://www.figma.com/file/2aw8Ws6531yqwtaviEjYGd/Ice-Wallet-Prototype?node-id=2%3A0"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            to view on Figma
          </span>
        </div>
      </div>
      <div class="project_row_right">
        <div class="project_preview">
          <img src={Mango} alt="Preview of Mango Farm Assets" />
        </div>
        <div class="project_description_right">
          <h3>Mango Farm Assets</h3>
          <p>
            Mango Farm Assets provides tools to create, store and use blockchain
            assets on the Ravencoin network. My role in this project was
            co-designer as well as building out the HTML framework and utilizing
            CSS to transfer designs from Figma onto the live site.
          </p>
          <span>
            Click{' '}
            <a
              href="https://mangofarmassets.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            to view
          </span>
        </div>
      </div>
    </div>
  );
};
