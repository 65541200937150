import React from 'react';
import './App.css';

import { About } from './components/about';
import { Nav } from './components/nav';
import { Tech } from './components/tech';
import { Portfolio } from './components/portfolio';
import { Resume } from './components/resume';
import { Contact } from './components/contact';

function App() {
  return (
    <div className='App'>
      {/* <Nav /> */}
      <About />
      <Tech />
      <Portfolio />
      <Resume />
      <Contact />
    </div>
  );
}

export default App;
