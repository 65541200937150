import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import maycieResume from "../images/resume.pdf";

export const Resume = () => {
  return (
    <div id="resume_container">
      <div id="portfolio_header" class="neons">
        <h1>RESUME</h1>
      </div>
      <div id="resume_wrapper">
        <Document file={maycieResume}>
          <Page pageNumber={1} />
        </Document>
      </div>
      <div id="mobile_resume">
        Click{" "}
        <a
          href="https://docs.google.com/document/d/1HEUjRHDiGW34-fVU8DronYu6QGNxY3zbg-QKwrI69ZA/edit?usp=sharing"
          target="_blanket"
          rel="noopener noreferrer"
        >
          here
        </a>{" "}
        to view my resume!
      </div>
    </div>
  );
};
