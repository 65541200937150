import React from 'react'
import Reactlogo from '../images/react.png'
import Javascript from '../images/javascript.png'
import Html from '../images/html.png'
import Css from '../images/css.png'
import Node from '../images/node.png'
import Figma from '../images/figma.png'


export const Tech = () => {
    return (
        <div id='tech_container'>
            <div id='tech_header' class='neons'>
                <h1>TECH STACK</h1>
            </div>
            <div id='logos_container'>
                <img src={ Reactlogo } />
                <img src={ Javascript } />
                <img src={ Html } />
                <img src={ Css } />
                <img src={ Node } />
                <img src={ Figma } />
            </div>
        </div>
    )
}